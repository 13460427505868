.form-container {
    display: flex;
    flex-direction: column;
    /*align-items: center;*/
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    text-align: left;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    margin: 0 auto;
    background-color: #f9f9f9;
}

.form-field {
    margin-bottom: 20px;
}

label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    width: 100%;
    box-sizing: border-box;
}

button {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
}

button:hover {
    background-color: #3e8e41;
}

@media screen and (max-width: 480px) {
    .form-container {
        max-width: 100%;
    }
}


input {
    width:100%;
}

.answer{
    min-height:200px !important;
}
.ql-editor, .ql-blank{
    min-height:200px !important;
    font-size:16px;

}

.question{
    /*min-height:200px;*/
    vertical-align: top;
}